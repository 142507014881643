<template>
  <div class="plans-container">
    <h2>{{ $t('pricing.title') }}<sup>®</sup></h2>
    <TabSwitcher class="m-auto plan-switcher" v-model="period" htmlRender :tabs="[
      {
        text: htmlRender ? $t('pricing.yearlyhtml') : $t('pricing.yearly'),
        value: 'year',
      },
      {
        text: htmlRendering ? $t('pricing.monthlyhtml') || $t('pricing.monthly') : $t('pricing.monthly'),
        value: 'month',
      },

    ]" />
    <div class="plans-list gap-column-sm">
      <div class="plan-item" :class="{
      [mode]: true
    }" v-for="mode in modes" :key="mode">
        <img :src="`/pics/${mode}.jpeg`" alt="" />
        <div class="plan-info">
          <h1 class="plan-name" :class="{
      [`${mode}-line`]: true
    }">{{ mode.toUpperCase() }}</h1>
          <h3 class="plan-price"><span class="bold">{{ currentPricing[mode] }}$</span>/{{ $t('pricing.month') }}</h3>
          <h3 class="plan-price-uah" :class="{ 'uah-white': mode === 'inside' }"><span class="bold">({{
      currentPricing[mode] * 40 }} грн</span>/{{
      $t('pricing.month') }})
          </h3>
          <div class="qrw-container-title-xs red-6-color" v-if="period == 'year'">
            {{ $t('pricing.save') }} 20%
          </div>
          <p class="plan-title">
            {{ $t(`pricing.${mode}_desc`) }}
          </p>
          <small class="plan-description qrw-text-description" :class="{
      'grey-2-color': mode == 'preview',
      'yellow-8-color': mode == 'takeaway',
      'blue-7-color': mode == 'delivery',
      'grey-2-color': mode == 'inside' || mode == 'preview',
    }">{{ $t(`pricing.${mode}_how`) }}</small>
        </div>
        <div class="bottom-info">
          <BaseButton variant="primary" :color="mode == 'inside' ? 'white-6' : 'black-4'" bold size="md"
            class="w-100 mb-8" @click="goToRegister">
            {{ $t('pricing.try_it') }}
          </BaseButton>
          <small :class="{
      'qrw-text-description': true,
      'yellow-8-color': mode == 'takeaway',
      'blue-7-color': mode == 'delivery',
      'grey-2-color': mode == 'inside' || mode == 'preview',
    }">{{ $t('pricing.freemium_limit') }}</small>
        </div>
      </div>
      <!-- <img src="../assets/icon-plus.svg" alt="plus" class="plus" /> -->

      <!-- <div class="plan-item del mr-24">
        <img src="../assets/delivery.jpeg" alt="" />
        <div class="plan-info">
          <h1 class="plan-name del-line">DELIVERY</h1>
          <h3 class="plan-price">{{ currentPricing.delivery }}$/<span>{{$t('pricing.'+mode)}}</span></h3>
          <p class="plan-title">
           {{ $t('pricing.delivery_desc')}}
          </p>
          <small class="plan-description lightblue"
            >{{ $t('pricing.delivery_how')}}</small
          >
        </div>
        <div class="bottom-info">
          
          <Button variant="dark" size="md" class="plan-button" @click="goToRegister"
            >{{ $t('pricing.connect_for') }}<b class="pl-5"> {{ currentPricing.delivery }}$/{{$t('pricing.'+mode)}}</b></Button
          >
          <small>{{ $t('pricing.freemium_limit') }}</small>
        </div>
      </div>
      <div class="plan-item tkw mr-24">
        <img src="../assets/takeaway.jpeg" alt="" />
        <div class="plan-info">
          <h1 class="plan-name tkw-line">TAKEAWAY</h1>
          <h3 class="plan-price">{{ currentPricing.preorder }}$/<span>{{$t('pricing.'+mode)}}</span></h3>
          <p class="plan-title">
            {{ $t('pricing.takeaway_desc')}}
          </p>
          <small class="plan-description lightorange"
            >{{ $t('pricing.takeaway_how') }}</small
          >
        </div>
        <div class="bottom-info">
          
          <Button variant="dark" size="md" class="plan-button" @click="goToRegister"
            >{{ $t('pricing.connect_for') }}<b class="pl-5">{{ currentPricing.preorder }}$/{{$t('pricing.'+mode)}}</b></Button
          >
          <small>
            {{ $t('pricing.freemium_limit') }}
          </small>
        </div>
      </div>

      <div class="plan-item ins">
        <img src="../assets/inside.jpeg" alt="" />
        <div class="plan-info">
          <h1 class="plan-name ins-line c-white">INSIDE</h1>
          <h3 class="plan-price c-white">{{ currentPricing.inside }}$/<span>{{$t('pricing.'+mode)}}</span></h3>
          <p class="plan-title c-white">
            {{ $t('pricing.inside_desc') }}
          </p>
          <small class="plan-description lightgreen"
            >
            {{ $t('pricing.inside_how')}}
            </small
          >
        </div>
        <div class="bottom-info">
          
          <Button variant="dark" size="md" class="plan-button" @click="goToRegister"
            >
            {{ $t('pricing.connect_for') }}
            <b class="pl-5"> {{ currentPricing.inside }}$/{{$t('pricing.'+mode)}}</b></Button
          >
          <small class="c-white">
            {{ $t('pricing.freemium_limit')}}
          </small>
        </div>
      </div> -->
    </div>
  </div>
</template>

<style scoped>
.plans-container {
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: 80px 64px;
}

.plan-switcher {
  width: 480px;
}

.inside {
  background: var(--black-4-color);
  color: #ffffff !important;
}

.inside .plan-name,
.inside .plan-title,
.inside .plan-price {
  color: #ffffff !important;
}

.delivery {
  background: #cbd7e6;
}

.takeaway {
  background: #f7cba3;
}

.preview {
  background: #f2f2f2;
}

.delivery-txt {
  color: #cbd7e6;
}

.takeaway-txt {
  color: #f7cba3;
}

.preview-txt {
  color: #f2f2f2;
}

.c-white {
  color: #ffffff !important;
}

.lightgreen {
  color: #8dddce !important;
}

.lightorange {
  color: #be7533 !important;
}

.lightblue {
  color: #6695cf !important;
}

h2 {
  font-family: 'ChadwickBold';
  font-size: 48px;
  font-weight: bold;
  color: #000000;
}

sup {
  font-size: 18px;
}

.m-auto {
  margin: 24px auto 0;
}

.pl-5 {
  padding-left: 5px;
}

.mr-24 {
  margin-right: 24px;
}

.plans-list {
  display: flex;
  align-items: center;
  padding-top: 40px;
}

.plan-item {
  width: 100%;
  position: relative;
  min-height: 520px;
  height: 520px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  transition: 0.3s;
}

.plan-item:hover {
  /* box-shadow: none; */
}

.plan-info {
  padding: 24px;
}

.plan-item img {
  width: 100%;
  display: block;
  border-radius: 8px 8px 0 0;
  max-height: 158px;
  min-height: 158px;
  object-fit: cover;
}

.plan-name {
  color: #1f1f1f;
  font-size: 28px;
  font-family: "ChadwickBold";
  padding-bottom: 8px;
  border-bottom: 1px solid #bfbfbf;
}

.delivery-line {
  border-bottom: 1px solid #aac4e3 !important;
}

.takeaway-line {
  border-bottom: 1px solid #f0b682 !important;
}

.inside-line {
  border-bottom: 1px solid var(--grey-1-color) !important;
}

.plan-price {
  font-size: 20px;
  color: #1f1f1f;
  padding-top: 8px;
}

.plan-price span {
  font-family: "Chadwick";
  font-weight: 400;
}

.plan-price-uah {
  font-size: 16px;
  color: #1f1f1f;
  padding-top: 4px;
  opacity: 0.8;
}

.plan-price-uah.uah-white {
  color: #ffffff;
}

.free {
  color: #e2223b;
}

.plan-title {
  font-size: 14px;
  color: #2e2e2e;
  padding-top: 16px;
}

.plan-description {
  padding-top: 8px;
  /* font-size: 10px; */
  display: block;
  /* line-height: 1.5; */
}

.plan-button {
  /* width: 100%;
  font-size: 13px;
  margin: 8px auto 0;
  text-align: center;
  justify-content: center;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0; */
}

img.plus {
  margin: 0 28px;
  display: block;
  width: 24px;
  height: 24px;
}

.bottom-info {
  position: absolute;
  bottom: 20px;
  padding: 0 16px;
  left: 0;
  right: 0;
}

.bottom-info small {
  /* font-size: 10px; */
}

@media screen and (min-width:1285px) {
  .plan-item {
    min-height: 66.3vh;
  }
}

@media screen and (max-width: 1200px) {
  .plans-container {
    padding: 60px 28px;
  }

  img.plus {
    margin: 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .mr-24 {
    margin-right: 0;
  }

  h2 {
    font-size: 30px;
  }

  .plan-switcher {
    width: 100%;
  }

  .plans-list {
    padding-top: 24px;
    flex-flow: column;
    row-gap: 24px !important;
  }

  .plan-item {
    width: 100%;
    min-height: 66vh;
  }

  img.plus {
    margin: 24px auto;
  }

  .tkw,
  .ins {
    margin-top: 24px;
  }
}

.w-100 {
  width: 100%;
}
</style>

<script>
import TabSwitcher from "@/components/TabSwitcher.vue";
// import Button from "@/components/Button.vue";
export default {
  data () {
    return {
      period: "year",
      modes: ['preview', 'takeaway', 'delivery', 'inside']
    };
  },
  components: {
    TabSwitcher,
    // Button,
  },
  methods: {
    goToRegister () {
      window.document.documentElement.scrollTop = document.getElementById(
        "register"
      ).offsetTop;
    }
  },
  computed: {
    monthlyPricings () {
      return {
        inside: 25,
        takeaway: 15,
        delivery: 20,
        preview: 10
      }
    },
    yearlyPricings () {
      return {
        inside: 22,
        takeaway: 12,
        delivery: 16,
        preview: 8
      }
    },
    currentPricing () {
      if (this.period == 'month') {
        return this.monthlyPricings;
      } else {
        return this.yearlyPricings;
      }
    }
  }
};
</script>