<template>
  <div>
    <header :class="scrollPosition > 50 ? 'transparent' : ''">
      <img
        src="/icons/qr-white-logo.svg"
        alt="logo"
        class="logo"
        @click="go2Main()"
      />
      <div class="header-actions">
        <a
          href="https://dashboard.qrwaiter.com.ua/login"
          class="login"
          target="_blank"
          data-testid="login_link"
        >{{ $t("navbar.login") }}</a>
        <a
          href="https://dashboard.qrwaiter.com.ua/register"
          class="register"
          target="_blank"
          data-testid="register_link"
        >{{ $t("navbar.register") }}</a>
        <div class="line"></div>
        <img
          :src="`/flags/${$i18n.locale}.png`"
          class="lan"
          @click="changeLocale"
        />
        <img
          :src="!menuOpened ? '/icons/menu-bars.svg' : '/icons/close-menu.svg'"
          @click="menuOpened = !menuOpened"
          alt
        />
      </div>
    </header>
    <div
      class="expand-menu"
      v-if="menuOpened"
    >
      <a
        class="log"
        href="https://queue.qrwaiter.com.ua/login"
        target="_blank"
      >
        {{ $t("navbar.login") }}
      </a>
      <!-- <a href="https://dashboard.qrwaiter.com.ua/register" target="_blank" class="reg" @click="menuOpened = false">
        {{ $t("navbar.register") }}
      </a> -->
      <img
        :src="`/flags/${$i18n.locale}.png`"
        class="lan"
        @click="changeLocale"
      />
      <!-- <router-link to="/login" class="log">Вхід</router-link>
      <router-link to="/register" class="reg">Зареєстуватись</router-link>-->
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      scrollPosition: null,
      menuOpened: false
    }
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    go2Main() {
      this.$router.push('/')
    },
    changeLocale() {
      const current = window.localStorage.getItem('locale') || this.$i18n.locale

      const available_locales = ['ua', 'ru', 'en']

      let index = available_locales.indexOf(current)

      if (index == -1) index = 0
      if (index + 1 == available_locales.length) {
        index = 0
      } else {
        index++
      }

      let new_locale = available_locales[index]

      this.$i18n.locale = new_locale
      window.localStorage.setItem('locale', new_locale)
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  }
}
</script>

<style scoped>
header {
  height: auto;
  left: 0;
  position: fixed;
  width: 100%;
  transition: 0.3s;
  z-index: 5;
  padding: 2vh 5vh;
  display: flex;
  justify-content: space-between;
  background: #1f1f1f;
}

header.transparent {
  background: rgba(29, 29, 27, 0.2);
  backdrop-filter: blur(14px);
}

header img.logo {
  height: 3.3vh;
  width: auto;
}

.header-actions {
  display: flex;
  align-items: center;
}
.header-actions img {
  display: none;
}

.header-actions img.lan {
  height: 4vh;
  width: auto;
  cursor: pointer;
  display: block;
}

.header-actions a {
  display: inline;
  text-decoration: none;
  color: #ededed;
  font-size: 2vh;
  line-height: 1.2;
  transition: 0.3s;
  padding: 1vh 2vh;
  border-radius: 8px;
}

a.register {
  background: #34ad96;
}

a.login {
  color: #ededed;
  background: #1f1f1f;
}

a.register:hover {
  background: #239680;
}

a.login:hover {
  color: #000;
  background: #f7cba3;
}

.line {
  height: 4.4vh;
  margin: 0 2.6vh;
  border-right: 1px solid #2e2e2e;
}

.header-actions a:first-child {
  margin-right: 1.7vh;
}

@media screen and (max-width: 768px) {
  .header-actions img.lan,
  .line {
    display: none;
  }
  img.lan {
    display: block;
    margin: 0 auto;
    height: 5vh;
    width: auto;
  }

  header {
    padding: 2vh;
  }
  .header-actions img {
    display: block;
  }
  .header-actions a {
    margin-left: 0;
    display: none;
  }
  .expand-menu {
    background: rgba(29, 29, 27, 0.2);
    backdrop-filter: blur(14px);
    position: fixed;
    height: 95vh;
    z-index: 5;
    width: 100%;
    top: 7vh;
    overflow-y: hidden;
  }
  .expand-menu a {
    display: block;
    text-align: center;
    font-size: 2.5vh;
    margin: 3.7vh auto;
    padding: 1vh 2vh;
    text-decoration: none;
  }
  .expand-menu a.log {
    background: #129b81;
    width: 12vh;
    color: #ededed;
  }
  .expand-menu a.reg {
    background: #ffd2a8;
    width: 25vh;
    color: #1d1d1d;
  }
}
</style>